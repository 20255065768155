import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SEOAdditional from "../components/seo-additional";
import Cta from "../components/cta";
import Hero from "../components/hero";
import FeatureList from "../components/feature-list";
import SideFeature from "../components/side-feature";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { UilAngleDown } from "@iconscout/react-unicons";

function Icon({ icon }) {
    let uil = {
        chevronDown: UilAngleDown
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

function PrivateOrder({ data }) {
    let d = data.contentfulPagePrivateOrder;
    const [itemsToShow, setItemsToShow] = useState(1);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: renderRichText(d.subtitle),
                }}
            />

            <section className="max-w-6xl mx-auto py-8 md:py-10">
                <div className="flex flex-wrap w-full">
                    {d.features.map((item) => (
                        <FeatureList key={item.title} props={item} />
                    ))}
                </div>
            </section>

            <SideFeature
                props={{
                    title: d.sideFeatures.title,
                    image: d.sideFeatures.image,
                    features: d.sideFeatures.features,
                }}
            />

            <Cta />

            {d.seoAdditional.length ?
                <section className="px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? 'hidden' : ''}
                        />
                    ))}

                    {itemsToShow == 1 ?
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() => setItemsToShow(d.seoAdditional.length)}
                            >
                                <span>Selengkapnya</span><Icon icon={'chevronDown'} />
                            </button>
                        </div>
                        : ''
                    }
                </section>
                : ''
            }
        </Layout>
    );
}

PrivateOrder.propTypes = {
    data: PropTypes.object,
};

Icon.propTypes = {
    icon: PropTypes.string,
};

export const query = graphql`
    {
        contentfulPagePrivateOrder {
            seo {
                title
                description
                keywords
            }
            seoAdditional {
                titleTag
                title
                content
            }
            label
            title
            subtitle {
                raw
            }
            features {
                title
                icon
                description
            }
            sideFeatures {
                title
                image
                features {
                    description
                    icon
                    title
                }
            }
        }
    }
`;

export default PrivateOrder;
