import React from "react";
import PropTypes from "prop-types";
import Image from "../components/image";

function SideFeature({ props }) {
    return (
        <section className="mx-auto max-w-6xl py-8 md:py-10">
            <div className="flex flex-col md:flex-row w-full md:space-x-16 items-center">
                <div className="w-full md:w-1/2 px-4 md:p-0 mb-6 md:mb-0">
                    <Image filename={props.image} alt="" />
                </div>
                <div className="w-full md:w-1/2 px-4 md:p-0">
                    <h2 className="text-3xl leading-tight mb-4 md:mb-8 font-display">
                        {props.title}
                    </h2>

                    {props.features.map((i) => (
                        <div key={i} className="flex flex-col py-5">
                            {i.icon !== "" ? (
                                <img
                                    className="w-8 h-8 flex-none mb-2"
                                    src={`../icons/myicons/${i.icon}`}
                                    alt=""
                                />
                            ) : null}

                            <h4 className="font-bold text-xl mb-2">
                                {i.title}
                            </h4>
                            <p className="text-gray-600 dark:text-gray-400">
                                {i.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

SideFeature.defaultProps = {};
SideFeature.propTypes = {
    props: PropTypes.array,
    image: PropTypes.text,
    title: PropTypes.text,
    icon: PropTypes.text,
    description: PropTypes.text,
    features: PropTypes.array,
};

export default SideFeature;
